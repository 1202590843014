export default {
  // ---------route------------------------
  "/wel": "首页",
  "/wel/index": "首页",
  "/info": "信息",
  "/info/index": "个人信息",
  "/content/sisters/among": "排行信息编辑",
  "/content/recommend/recommendRedact": "推荐信息编辑",
  "/content/management/compile": "内容详情管理",
  "/user/details/basicsInfo": "用户基础信息",
  "/user/details/index": "用户详情",
  "/distributor-statistics/package-upload": "渠道数据统计",
  "/distributor-statistics/package-upload/details": "代理统计详情",
  "/distributor-manage/distributorlist/distributorDetails/index": "代理详情",
  "/distributor-manage/distributorlist/distributorUserList/index":
    "用户列表信息",
  "/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index":
    "用户信息",
  "/marketing/adlog": "广告日志",
  "/marketing/adlog/details": "广告详情",
  // ......from  api........
  "/admin": "权限管理",
  "/admin/user/index": "用户管理",
  "/admin/menu/index": "菜单管理",
  "/admin/role/index": "角色管理",
  "/admin/platformManage/index": "平台管理",
  "/admin/securityCenter/index": "安全中心",
  "/user": "用户管理",
  "/user/userList/index": "用户列表",
  "/video-manage": "审核管理",
  "/video-manage/violationCheck/index": "违规审核",
  "/video-manage/audit-record/audit-record": "审核记录",
  "/video-manage/audit-record/my-audit-record": "我的审核记录",
  "/video-manage/strategy/allocation": "审核策略管理",
  "/video-manage/videoUpload/index": "供应商视频上传",
  "/video-manage/supplyVideoList/index": "供应商视频上传列表",
  "/video-manage/consumerVideoList/index": "C端视频列表",
  "/video-manage/triateralVideoList/index": "三方视频列表",
  "/video-manage/waterMarkList/index": "水印处理",
  "/video-manage/video-processing-progress/index": "视频处理进度",
  "/video-manage/videoList/index": "视频上传列表",
  "/setting": "系统管理",
  "/admin/videoUploadSet/index": "视频相关设置",
  "/admin/dict/index": "字典管理",
  "/adminId": "分布式ID管理",
  "/admin/major-key/index": "分布式主键管理",
  "/admin/data-monit/index": "数据库监控",
  "/admin/cache-monit/index": "缓存监控",
  "/content": "内容管理",
  "/content/management/index": "视频管理",
  "/content/discuss/claims": "评论审核",
  "/content/discuss/record": "弹幕审核",
  "/content/recommend/manage": "推荐管理",
  "/content/sisters/brothers": "排行管理",
  "/content/classify/index": "标签管理",
  "/content/sensitive/index": "敏感词库",
  "/content/navigation/navitber": "导航页管理",
  "/content/triateralplatform/index": "三方平台管理",
  "/report-and-feedback": "举报与反馈",
  "/report-and-feedback/video/index": "视频举报",
  "/report-and-feedback/comment-on/index": "评论举报",
  "/report-and-feedback/bullet-chat/index": "弹幕举报",
  "/report-and-feedback/user/index": "用户举报",
  "/report-and-feedback/violation/index": "违规原因",
  "/report-and-feedback/advertising-complaint/index": "广告投诉",
  "/marketing": "营销中心",
  "/marketing/planning/index": "活动中心",
  "/marketing/game/index": "游戏中心",
  "/marketing/advertise/index": "广告管理",
  "/marketing/topsearch/index": "热搜管理",
  "/marketing/message-center/index": "消息中心",
  "/marketing/topicstore/index": "话题库",
  "/marketing/adlog/index": "广告日志",
  "/Appinventory": "App管理",
  "/Appinventory/mymanage/mine": '"我的"管理',
  "/admin/version/index": "版本管理",
  "/report-and-feedback/help/index": "帮助问题管理",
  "/admin/suggest-feed/index": "反馈和建议",
  "/Appinventory/language/index": "国际化管理",
  "/Appinventory/installPackgeUpload/index": "安装包上传",
  "/business-manage": "商业化管理",
  "/business-manage/coinMenu/index": "金币套餐",
  "/business-manage/memberMenu/index": "会员套餐",
  "/business-manage/memberList/index": "会员列表",
  "/business-manage/orderGoodsList/index": "商品订单列表",
  "/business-manage/memberBenefits/index": "会员权益",
  "/distributor-manage": "渠道商管理",
  "/distributor-manage/distributorlist/index": "渠道商列表",
  "/distributor-manage/distributor-scheme/index": "渠道商方案管理",
  "/distributor-statistics": "渠道数据统计",
  "/distributor-statistics/package-upload/index": "安装包统计",
  "/withdraw": "提现管理",
  "/withdraw/distributor-withdraw/index": "渠道提现列表",
  "/withdraw/fission-withdraw/index": "裂变提现列表",
  "/withdraw/proxy-withdraw/index": "代理提现列表",
  "/admin/SMsMgr/index": "短信备案",
  "/admin/SMsMgr/smsTemplate": "短信模版",
  "/content/referralPool/index": "推荐池管理",
  "/business-manage/channelRevenueDetail/index": "收益明细表",
  "/burialevent": "埋点事件",
  "/burialevent/visualization/index": "可视化埋点",
  "/burialevent/attributestore/index": "属性库",
  "/video-manage/dismissReasons/index": "驳回原因",
  "/video-manage/dissentingPool/index": "异议池",
  // -----------------common start-------------------
  search: "搜索",
  reset: "重置",
  normal: "正常",
  disable: "禁用",
  check: "查看",
  edit: "编辑",
  add: "新增",
  change_password: "修改密码",
  enable: "启用",
  serial_number: "序号",
  start_time: "开始时间",
  end_time: "结束时间",
  updated_by: "更新人",
  update_time: "更新时间",
  operate: "操作",
  delete: "删除",
  determine: "确定",
  cancel: "取消",
  successfully_deleted: "删除成功",
  prompt: "提示",
  confirm_content: "此操作将永久删除该数据, 是否继续?",
  confirm_forbidden: "此操作将被禁用, 是否继续?",
  confirm_enable: "此操作将启用, 是否继续?",
  confirm_disable:
    "确定禁用此关账号？删除之后该代理下的客户产生收益将不再继续统计?",
  please_enter: "请输入",
  please_select: "请选择",
  successfully_added: "新增成功",
  successfully_edited: "编辑成功",
  sort: "排序",
  status: "状态",
  unit_day: "(天)",
  currency: "元",
  phone: "手机号",
  order_num: "订单号",
  consume_type: "消费类型",
  pay_type: "支付类型",
  pay_way: "支付方式",
  goods_name: "商品名称",
  pay_time: "支付时间",
  role: "角色",
  no_selected_items: "未有选中项",
  password: "密码",
  confirm_password: "确认密码",
  platform: "平台",
  create_time: "创建时间",
  numbers_and_english: "数字和英文",
  user_name: "用户名",
  existed: "已经存在",
  the_length_should_be: "长度应在",
  within_bits: "位以内",
  contact_info: "联系方式",
  correct: "正确的",
  telephone: "电话",
  batch: "批量",
  batch_delete: "批量删除",
  next_step: "下一步",
  export: "导出",
  add_time: "添加时间",
  verification_failed: "验证不通过",
  settlement_method: "结算方式",
  index_title: "后台管理系统 V 4.5.3-01.16",
  exit_full_screen: "退出全屏",
  full_screen: "全屏",
  home_page: "首页",
  personal_information: "个人信息",
  exit_system: "退出系统",
  platform_switch_successful: "平台切换成功",
  no_menu_found: "没有发现菜单",
  close_other: "关闭其他",
  close_all: "关闭全部",
  more: "更多",
  examine: "审核",
  handle: "处理",
  schedule: "进度",
  withdrawal_method: "提现方式",
  withdrawal_amount: "提现金额",
  application_time: "申请时间",
  reason: "原因",
  pass: "通过",
  by_success: "通过成功",
  view_credentials: "查看凭证",
  proxy_account: "代理账号",
  agent_name: "代理名称",
  collection_account: "收款账号",
  voucher: "凭证",

  // ---------route------------------------
  "/agent-manage/order-list/index": "代理提现订单列表",

  // ---------权限管理------------------------
  // 用户管理
  select_all: "全选",
  please_enter_the_password_again: "请再次输入密码",
  the_two_passwords_not_match: "两次输入密码不一致",
  real_name: "真实姓名",

  // ---------用户管理------------------------

  // ---------视频管理------------------------
  // 供应商视频上传
  upload_failed: "上传失败",
  draft: "草稿",

  // ---------系统管理------------------------

  // ---------内容管理------------------------

  // ---------举报与反馈------------------------

  // ---------营销中心------------------------

  // ---------APP管理------------------------
  // 我的管理
  picture_preview: "图片预览",
  feature_name: "功能名称",
  affiliation_module: "所属模块",
  icon: "图标",
  routing_path: "路由路径",
  please_upload_pictures: "请上传图片",

  // ---------商业化管理------------------------
  // 金币套餐
  amount: "金额",
  number_purchases: "购买次数",
  obtaining_gold_coins: "获得金币",
  single_account_purchase_restrictions: "单账号购买限制",

  // 会员套餐
  subscription_type: "订阅类型",
  menu_name: "套餐名称",
  fact_price: "实际价格",
  original_price: "原价",
  member_validity_period: "会员有效期",
  member_validity_period_day: "会员有效期（天）",
  rules_menu_name: "请输入2-6个字符",

  // 会员列表
  nick_name: "昵称",
  membership_type: "会员类型",
  pay_time: "支付时间",
  residual_interest: "剩余次数",
  expire_time: "到期时间",

  // 商品订单列表
  total_income: "总收入",
  month_income: "本月收入",
  day_income: "当日收入",
  refund_amount: "退款金额",

  // 会员权益
  consumer_rights: "消费权益",
  consumer_rights_tip: "视频消费权益,指超级会员购买付费视频进行多少折扣",
  recharge_coins: "充值金币",
  discounts: "折",
  paid_video: "付费视频",
  offline_download_Benefits: "离线下载权益",
  offline_download_Benefits_tip:
    "视频离线下载权益,指每个超级会员每个自然月可下载多少个视频",
  moreonthly_offline_downloads: "每月离线下载次数",
  interactive_rights: "互动权益",
  interactive_rights_tip: "可设置是否可以进行评论，弹幕的权限",
  comment: "评论",
  comment_tip: "超级会员将可以对视频进行评论发布",
  barrage: "弹幕",
  barrage_tip: "超级会员将可以对视频进行弹幕发布",
  collect: "收藏",
  collect_tip: "超级会员将可以对视频进行视频收藏",
  save: "保存",

  // ---------渠道商管理 -----------------------
  // 渠道商列表-
  full_name: "姓名",
  channel_merchant_account: "渠道商账号",
  channel_merchant_name: "渠道商名称",
  channel_merchant_list: "渠道商列表",
  scheme_permissions: "方案权限",
  number_of_owned_projects: "拥有项目数量",
  number_of_installation_packages_held: "持有安装包数量",
  number_of_agents_owned: "拥有代理人数",
  project_management: "项目管理",
  edit_password: "修改密码",
  success_enabled: "启用成功",
  success_disable: "禁用成功",
  login_password: "登录密码",
  set_password: "设置6位密码",
  set_scheme_permissions: "设置方案权限",
  settlement: "结算",
  programme: "方案",
  level_commission: "级金币",
  illustrate: "说明",
  illustrate_content:
    "1~5级佣金将用于代理名下的c端用户佣金分成,比如:当1~5级总和为40%,那么剩下的60%将作为给平台，渠道，代理按照选择的方案来分成。",
  correct: "正确的",
  password_length: "密码长度最少6位,最长20位",
  password_format: "密码只能包含字母和数字",
  commission_rate: "金币比例",
  commission_type: "金币比例必须为正整数",
  commission_total: "佣金比例总和不能大于等于100",
  add_commission: "最多添加5级金币",
  select_programme: "请至少选择一个方案",
  modify_account: "修改账号",
  verify_password: "两次密码不一致",

  // -渠道商管理 渠道商列表 渠道商项目
  channel_Details: "渠道详情",
  channel_revenue: "渠道收益",
  agency_income: "代理收益",
  project_name: "项目名称",
  system: "系统",
  version: "版本",
  total_commission: "总贡献佣金",
  withdrawn_commission: "已提现佣金",
  withdrawable_commission: "可提现佣金",
  agent_details: "代理详情",
  settlement_plan: "结算方案",

  // -渠道商方案管理-
  cps_programme: "cps方案",
  cpa_programme: "cpa方案",
  platform_division: "平台分成",
  channel_merchant_sharing: "渠道商分成",
  agent_sharing: "代理商分成",
  scheme_name: "方案名称",
  set_percentage_value: "设置百分比值",
  rule: "规则",
  positive_integer: "只能输入正整数",
  input_total: "输入值总和不能超过",
  three_input_total: "三者输入的总和必要等于",

  // ---------渠道数据统计 ----------------------
  //--安装包统计-
  wrap_count: "安装包统计",
  acquisition_time: "获取时间",
  invite_number: "邀请数量",
  agent_statistics_details: "代理商统计详情",

  // ---------提现管理 ----------------------
  // -渠道提现列表-
  order_number: "订单号",
  channel_withdrawal_list: "渠道提现列表",
  fail_to_succeed: "不通过成功",
  processing_results: "处理结果",
  success: "成功",
  fail: "失败",
  failure_reason: "失败原因",
  current_withdrawal: "当前结算共可提现金额",
  Channel_settlement: "渠道商结算方式",
  reason_for_failure: "不通过原因",
  noPass: "不通过",

  // -裂变提现列表-
  user_nickname: "用户昵称",
  user_account: "用户账号",
  audit_time: "审核时间",
  reject: "驳回",
  reject_success: "驳回成功",
  withdrawal_success: "提现成功",
  withdrawal_failed: "提现失败",
  fission_withdrawal_list: "裂变提现列表",

  // -代理提现列表-
  withdrawal_path: "提现路径",
  proxy_withdrawal_list: "代理提现列表",
  query: "查询",
  enableThisVersion: "启用此版本",
  downloadWebsite: "下载网址",
  abandoned: "废弃",
};
