<template>
  <div class="avue-tags" v-if="showTag">
    <div
      v-if="contextmenuFlag"
      class="avue-tags__contentmenu"
      :style="{ left: contentmenuX + 'px', top: contentmenuY + 'px' }"
    >
      <div class="item" @click="closeOthersTags">关闭其他</div>
      <div class="item" @click="closeAllTags">关闭全部</div>
    </div>
    <div
      class="avue-tags__box"
      :class="{ 'avue-tags__box--close': !website.isFirstPage }"
    >
      <el-tabs
        v-model="active"
        type="card"
        @contextmenu.native="handleContextmenu"
        :closable="tagLen !== 1"
        @tab-click="openTag"
        @edit="menuTag"
      >
        <el-tab-pane
          :key="item.value"
          v-for="item in tagList"
          :label="getTabLabel(item)"
          :name="item.value"
        >
        </el-tab-pane>
      </el-tabs>
      <el-dropdown class="avue-tags__menu">
        <el-button type="primary">
          更多
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="closeOthersTags">关闭其他</el-dropdown-item>
            <el-dropdown-item @click.native="closeAllTags">关闭全部</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { on, off } from '@/util/eventBus';

export default {
  name: "tags",
  data() {
    return {
      active: "",
      contentmenuX: "",
      contentmenuY: "",
      contextmenuFlag: false,
    };
  },
  created() {},
  mounted() {
    on('clearTab', this.eventBusListener);
    this.setActive();
  },
  beforeDestroy() {
    off('clearTab', this.eventBusListener);
  },
  watch: {
    tag() {
      this.setActive();
    },
    contextmenuFlag(contextmenuShow) {
      if (contextmenuShow) {
        window.addEventListener("mousedown", this.watchContextmenu);
      }
    },
  },
  computed: {
    ...mapGetters(["tagWel", "tagList", "tag", "website"]),
    ...mapState({
      showTag: (state) => state.common.showTag,
    }),
    tagLen() {
      return this.tagList.length || 0;
    },
  },
  methods: {
    eventBusListener(path) {
      this.$store.commit('EDIT_TAG', { path, userId: this.$route.query.userId, tabsId: this.$route.query.tabsId });
      this.setActive();
    },
    watchContextmenu(e) {
      if (!this.$el.contains(e.target)) {
        this.contextmenuFlag = false;
        window.removeEventListener("mousedown", this.watchContextmenu);
      }
    },
    handleContextmenu(event) {
      let target = event.target;
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
    
      let flag = false;
      if (target.className.indexOf("el-tabs__item") > -1) flag = true;
      else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
        target = target.parentNode;
        flag = true;
      }
      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.tagName = target.getAttribute("aria-controls").slice(5);
        this.contextmenuFlag = true;
      }
    },
    //激活当前选项
    setActive() {
      this.active = this.tag.value;
    },
    menuTag(value, action) {
      if (action === "remove") {
        let { tag, key } = this.findTag(value);
        this.$store.commit("DEL_TAG", tag);
        if (tag.value === this.tag.value) {
          tag = this.tagList[key === 0 ? key : key - 1]; //如果关闭本标签让前推一个
          this.openTag(tag);
        }
      }
    },
    openTag(item) {
      let tag;
      if (item.props?.name) {
        tag = this.findTag(item.props.name).tag;
      } else {
        tag = item;
      }
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: tag.label,
          src: tag.value,
        }),
        query: tag.query,
      });
    },
    closeOthersTags() {
      this.contextmenuFlag = false;
      this.$store.commit("DEL_TAG_OTHER");
    },
    findTag(value) {
      let tag, key;
      this.tagList.map((item, index) => {
        if (item.value === value) {
          tag = item;
          key = index;
        }
      });
      return { tag: tag, key: key };
    },
    closeAllTags() {
      this.contextmenuFlag = false;
      this.$store.commit("DEL_ALL_TAG");
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          src: this.tagWel.value,
        }),
        query: this.tagWel.query,
      });
    },
    getTabLabel({ label, query }) {
      if (query.userId && query.nickname && query.showTagName) {
        return `${label}(${query.userId}${query.nickname})`;
      }
      return label;
    },
  },
};
</script>