import Layout from "@/page/index/index";
const router_details = [
  {
    path: "/content",
    component: Layout,
    name: "内容管理",
    children: [
      {
        path: "/content/management/details",
        name: "内容详情管理", //内容管理/视屏管理/视频编辑
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/content/management/details"
          ),
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    name: "会员管理",
    children: [
      {
        path: "/user/detailsCY/index",
        name: "会员详情",
        component: () =>
          import(/* webpackChunkName: "page" */ "@/views/user/detailsCY/index"),
      },
    ],
  },
  {
    path: '/content',
    component: Layout,
    name: '楼凤管理',
    children: [
      {
        path: '/content/loufengDetails/index',
        name: '楼凤详情',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/content/loufeng/details.vue'),
      },
    ],
  },
  {
    path: "/distributor-statistics",
    component: Layout,
    name: "渠道数据统计",
    children: [
      {
        path: "/distributor-statistics/package-upload/details",
        name: "代理统计详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-statistics/package-upload/details.vue"
          ),
      },
    ],
  },
  {
    path: "/distributor-manage",
    component: Layout,
    name: "渠道商管理",
    children: [
      {
        path: "distributorlist/webmasterDetails/index",
        name: "查看详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/webmasterDetails/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/dataStatistics/installPackageDataStatistics",
        name: "安装包数据统计",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/dataStatistics/installPackageDataStatistics"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/dataStatistics/projectStatistice",
        name: "基础统计表",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/dataStatistics/projectStatistice"
          ),
      },
      {
        path: '/distributor-manage/distributorlist/report/index',
        name: '渠道数据统计表',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/distributor-manage/distributorlist/report/index'
          ),
      },
      {
        path: '/distributor-manage/distributorlist/reduceSettings/index',
        name: '渠道扣量配置',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/distributor-manage/distributorlist/reduceSettings/index'
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorDetails/index",
        name: "代理详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorDetails/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorUserList/index",
        name: "用户列表信息",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorUserList/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index",
        name: "用户信息",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index"
          ),
      },
    ],
  },
  {
    path: "/marketing",
    component: Layout,
    name: "营销中心",
    children: [
      {
        path: "/marketing/adlog/details",
        name: "广告详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/marketing/adlog/details"
          ),
      },
    ],
  },
  {
    path: "/setting",
    component: Layout,
    name: "系统管理",
    children: [
      {
        path: "/admin/SMsMgr/smsTemplate",
        name: "短信模版",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/admin/SMsMgr/smsTemplate"
          ),
      },
    ],
  },
];
const api_route = [
  {
    path: "/dataBoard",
    component: Layout,
    children: [],
  },
  {
    path: "/record",
    component: Layout,
    children: [],
  },
  {
    path: "/Appinventory",
    component: Layout,
    children: [],
  },
  {
    path: "/merchant",
    component: Layout,
    children: [],
  },
  {
    path: "/productManagement",
    component: Layout,
    children: [],
  },
  {
    path: "/user",
    component: Layout,
    children: [],
  },
  {
    path: "/video-manage",
    component: Layout,
    children: [],
  },
  {
    path: "/content",
    component: Layout,
    children: [],
  },
  {
    path: "/marketing",
    component: Layout,
    children: [],
  },
  {
    path: "/gameCenter",
    component: Layout,
    children: [],
  },
  {
    path: "/gameAgent",
    component: Layout,
    children: [],
  },
  {
    path: "/report-and-feedback",
    component: Layout,
    children: [],
  },
  {
    path: "/rules-manage",
    component: Layout,
    children: [],
  },
  {
    path: "/marketing/blogger",
    component: Layout,
    children: [],
  },
  {
    path: "/nationalagent",
    component: Layout,
    children: [],
  },
  {
    path: "/business-manage",
    component: Layout,
    children: [],
  },
  {
    path: "/withdraw",
    component: Layout,
    children: [],
  },
  {
    path: "/burialevent",
    component: Layout,
    children: [],
  },
  {
    path: "/admin",
    component: Layout,
    children: [],
  },
  {
    path: "/setting",
    component: Layout,
    children: [],
  },
  {
    path: "/distributor-statistics",
    component: Layout,
    children: [],
  },
  {
    path: "/distributor-manage",
    component: Layout,
    children: [],
  },
  {
    path: "/report",
    component: Layout,
    children: [],
  },
];
const routeList = [
  {
    path: "/wel",
    component: Layout,
    redirect: "/wel/index",
    children: [
      {
        path: "index",
        name: "首页",
        component: () => import(/* webpackChunkName: "views" */ "@/page/wel"),
      },
    ],
  },
  {
    path: "/info",
    component: Layout,
    redirect: "/info/index",
    children: [
      {
        path: "index",
        name: "个人信息",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/admin/user/info"),
      },
    ],
  },
  ...api_route,
];

routeList.forEach((v) => {
  router_details.forEach((m) => {
    if (v.path === m.path) {
      if (Array.isArray(v.children) && Array.isArray(m.children)) {
        v.children.push(...m.children);
      }
    }
  });
});

export default routeList;
