import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import ElementPlus, { ElTable, ElForm } from 'element-plus';
// import AvueFormDesign from '@sscfaith/avue-form-design';
import * as echarts from 'echarts/core';
import Avue from '@smallwei/avue';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import axios from './api/axios';
import 'element-plus/dist/index.css';
// import '@smallwei/avue/lib/index.css';
import { loadStyle } from './util/util';
import * as urls from '@/config/env';
import { iconfontUrl, iconfontVersion } from '@/config/env';
import * as filters from './filters'; // 全局filter
import './styles/common.scss';
import basicContainer from './components/basic-container/main';
import i18n from '@/lang/index'; // 国际化
import './permission'; // 权限
import { setupErrorHandler } from './error'; // 日志

// 修復 Vue Router push 重複點擊問題

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// 配置 ECharts
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
]);

// 創建應用
const app = createApp(App);

ElTable.props.scrollbarAlwaysOn = true;
ElForm.props.validateOnRuleChange = false;
// 使用插件
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(ElementPlus);
app.use(Avue);
// app.use(AvueFormDesign);
app.use(i18n);

setupErrorHandler(app);

// 全局註冊組件
app.component('basicContainer', basicContainer);

// 加載全局屬性（替代 Vue.prototype）
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$echarts = echarts;
Object.keys(urls).forEach(key => {
  app.config.globalProperties[key] = urls[key];
});

// 加載全局過濾器
Object.keys(filters).forEach(key => {
  app.config.globalProperties[`$filter_${key}`] = filters[key];
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 動態加載阿里云字體
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele));
});

// 處理 el-radio-group 的瀏覽器兼容問題
app.directive('removeAriaHidden', {
  beforeMount(el) {
    const ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach(item => {
      item.removeAttribute('aria-hidden');
    });
  },
});

app.mount('#app');
