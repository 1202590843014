// import Vue from "vue";
// import locale from "element-plus/lib/locale";
// import enLocale from 'element-plus/es/locale/lang/en';
import zhLocale from 'element-plus/es/locale/lang/zh-cn';
// import zhTwLocale from 'element-plus/es/locale/lang/zh-tw';
// import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n';

import store from '@/store';

// 引入自己的语言包
import zhCn from './package/zh-cn';
// import zhTw from './package/zh-tw';
// import en from './package/en';

// 引入自己的语言包
import zhCn2 from './package2/zh-cn';
// import zhTw2 from './package2/zh-tw';
// import en2 from './package2/en';

// element ui 国际化

// 国际化

// Vue.use(VueI18n);

// 配置i18n语言包
const messages = {
  // en: {
  //   ...en,
  //   ...enLocale,
  //   ...en2,
  // },
  'zh-cn': {
    ...zhCn,
    ...zhLocale,
    ...zhCn2,
  },
  // 'zh-tw': {
  //   ...zhTw,
  //   ...zhTwLocale,
  //   ...zhTw2,
  // },
};
// 国际化配置
const i18n = createI18n({
  locale: store.state.common.lang, // 默认中文
  messages,
  globalInjection: true,
});
// locale.i18n((key, value) => i18n.global.t(key, value)); // 将 Element UI 的翻译函数绑定到 Vue-i18n

export default i18n;
