import { createRouter, createWebHashHistory } from 'vue-router';
import comPage from './comPage';
import views from './views';
import AvueRouter from './avue-router';
import Store from '../store/index';

// const Router = new VueRouter({
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (from.meta.keepAlive) {
//       from.meta.savedPosition = document.body.scrollTop;
//     }
//     return {
//       x: 0,
//       y: to.meta.savedPosition || 0,
//     };
//   },
//   routes: [...comPage, ...views],
// });

const Router = createRouter({
  history: createWebHashHistory(),
  routes: [...comPage, ...views],
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

AvueRouter.install(Router, Store);
Router.$avueRouter.formatRoutes(Store.state.user.menu, true);

export default Router;
