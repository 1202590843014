import { reactive } from 'vue';

export const EventBus = reactive({
  events: {},
});

export function emit(event, data) {
  if (EventBus.events[event]) {
    EventBus.events[event].forEach(callback => callback(data));
  }
}

export function on(event, callback) {
  if (!EventBus.events[event]) {
    EventBus.events[event] = [];
  }
  EventBus.events[event].push(callback);
}

export function off(event, callback) {
  if (EventBus.events[event]) {
    EventBus.events[event] = EventBus.events[event].filter(cb => cb !== callback);
  }
}
