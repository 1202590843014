<template>
  <div class="avue-sidebar">
    <logo></logo>
    <el-scrollbar style="height: 100%">
      <div v-if="validatenull(menu)" class="avue-sidebar--tip">
        {{ $t("no_menu_found") }}
      </div>
      <el-menu
        unique-opened
        :default-active="nowTagValue"
        mode="vertical"
        :show-timeout="200"
        :collapse="keyCollapse"
      >
        <sidebar-item
          :menu="menu"
          :screen="screen"
          first
          :props="website.menu.props"
          :collapse="keyCollapse"
        ></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import logo from "../logo";
import sidebarItem from "./sidebarItem";

export default {
  name: "sidebar",
  components: { sidebarItem, logo },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("GetMenu", { type: true, id: -1 }).then((data) => {
      if (data.length === 0) return;
      this.$router.$avueRouter.formatRoutes(data, true);
    });
  },
  computed: {
    ...mapGetters(["website", "menu", "tag", "keyCollapse", "screen"]),
    nowTagValue: function () {
      return this.$router.$avueRouter.getValue(this.$route);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-sub-menu__title) {
  color: #ffffffb3;
}

:deep(.el-menu) {
  background-color: transparent;
  border-right: unset
}

:deep(.el-sub-menu__title:hover) {
  background-color: transparent;
  color: #fff;
}

:deep(.el-sub-menu__title div i) {
  margin-right: 10px;
  vertical-align: middle;
}
</style>
